'use strict';

var $ = require('jquery'),
    frontendKit = require('../frontend/utils');

require('jquery-mask-plugin');

module.exports = {
    maskApplier: frontendKit.debounce(function(ev) {
        if(ev == undefined || ev == null || ev == '') return;
        $('#dropdown-postcode-input').mask('00000-000');

        $('body').off('click', '#dropdown-postcode-input', this.maskApplier);
        $('body').off('click', '#minicart-postcode-input', this.maskApplier);
    }, 1000),

    bindEvHandlers: function() {
        $('body').on('click', '#dropdown-postcode-input', this.maskApplier);
        $('body').on('click', '.change-postal-code', this.maskApplier);
        $('body').on('click', '#minicart-postcode-input', this.maskApplier);
    },

    init: function() {
        $(document).ready(function() {
            $('#dropdown-postcode-input').mask('00000-000');
            $('#minicart-postcode-input').mask('00000-000');
        });
    }
};
